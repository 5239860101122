import React, { useState } from "react"
import axios from "axios"
import Layout from "../components/main/layout/Layout"
import s from "./Start.module.scss"
import { Helmet } from "react-helmet"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"

import { Input } from "../components/common/input/Input"
import { Button } from "../components/common/button/Button"
import { navigate } from "gatsby"
import { Footer } from "../components/main/footer/Footer"

const Start = () => {
  function validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  }

  const _start = async () => {
    serError(false)
    if (sending) return
    if (validateEmail(emailAddress)) {
      setSending(true)
      let req = await axios.post(
        `https://pintless.tennents.co.uk/.netlify/functions/user-login`,
        {
          emailAddress: emailAddress,
          marketingOK: marketingOK,
        }
      )
      setSending(false)
      setSent(true)

      trackCustomEvent({
        category: "User",
        action: "Signup",
        label: marketingOK ? "Marketing OK" : "Marketing NO",
      })

      // navigate(`/game/create/${req.data.code}/${req.data.gameId}`)
    } else {
      serError(true)
    }
  }

  const [emailAddress, setEmailAddress] = useState("")
  const [marketingOK, setMarketingOK] = useState(false)
  const [sending, setSending] = useState(false)
  const [sent, setSent] = useState(false)
  const [error, serError] = useState(false)

  return (
    <Layout>
      <Helmet>
        <title>Create Your Own Online Pub Quiz | Tennent’s Pintless</title>
        <meta
          name="description"
          content="Host an online pub quiz with family or friends with Pintless, Tennent’s new pub quiz app. Choose questions and enjoy your pub quiz at home."
        />
      </Helmet>
      <div className={s.Wrapper}>
        <div className={s.Inner}>
          {!sending && !sent && (
            <>
              <h2>We just need some details to get started.</h2>

              <p>
                Pop your email address in the box below to allow us to get your
                quiz set-up.
              </p>
              <Input
                label={"Email Address"}
                value={emailAddress}
                onChange={val => {
                  setEmailAddress(val.toLowerCase())
                }}
              />
            </>
          )}
          {error && (
            <span className={s.Error}>Please enter a valid email address.</span>
          )}
          {sent && (
            <div className={s.SentConfrim}>
              <h3>You're ready to start!</h3>
              <p>
                You'll find an email from us with the link to setup your quiz.
                <br /> <br />
                (Remember and check your junk folder!)
              </p>
            </div>
          )}
          {!sent && sending && <h4>Loading...</h4>}
          {!sent && !sending && (
            <>
              <Button className={s.Start} onClick={_start}>
                <span>Continue</span>
              </Button>

              <div
                className={s.Marketing}
                onClick={() => setMarketingOK(!marketingOK)}
              >
                <div
                  className={`${s.Checkbox} ${
                    marketingOK && s.Checkbox___checked
                  }`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 14.414 11.414"
                  >
                    <g
                      data-name="Group 488"
                      fill="none"
                      stroke="#000"
                      stroke-width="2"
                    >
                      <path data-name="Line 103" d="M.707 6.708l4 4" />
                      <path data-name="Line 104" d="M13.707.708l-10 10" />
                    </g>
                  </svg>
                </div>

                <p className={s.MarketingCopy}>
                  Check this box if you want to receive marketing communications
                  from Tennent’s Lager.{" "}
                  <a
                    href="https://tennents.co.uk/privacy-policy/"
                    target="_blank"
                  >
                    Privacy Policy
                  </a>
                </p>
              </div>
            </>
          )}
        </div>
      </div>
      <Footer />
    </Layout>
  )
}

export default Start
